import React from 'react';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { role } = jwt.decode(localStorage.getItem('access-token')) || {};

  return (
    <Route
      {...rest}
      render={props => {
        if (!role) {
          // If the user is not logged in, redirect to signin page
          return <Redirect to="/sign-in" />;
        }

        if (allowedRoles && allowedRoles.indexOf(role) === -1) {
          // If the user role is not allowed, redirect to their default route
          if (role === 'omama') {
            return <Redirect to={'/omama/plan'} />;
          }
          if (role === 'mentor' || role === 'supervizor') {
            return <Redirect to={'/admin/users'} />;
          }
          if (role === 'admin') {
            return <Redirect to={'/admin'} />;
          }
        }

        // If the user is allowed, render the component
        return <Component {...props} />;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ProtectedRoute;
